
@import '../../_css//globals.scss';

.wrapper {
    width: 100%;
    height: 100%;
    overflow: hidden; /* will contain if #first is longer than #second */
    display: flex;
}
.biglogo {
    width: 80%;
    height: 100%;
    flex: 0 1 auto;
    float:left; /* add this */
    box-sizing: border-box;
    background-color: $themeprimary;
    padding: 20em;
}

.biglogo img {
  object-fit: contain;
  height: 100%;
  width: 100%;
  //padding: 10em;
  flex: 0 1 auto;
/* or
  object-fit: cover; */
} 
.logincontainer {
    overflow: hidden; /* if you don't want #second to wrap below #first */
    padding-top: 10%;
    padding-left: 2%;
    padding-right: 2%;
}

.logincontainer h1 {
    text-align: center;
}

.logincontainer Button{
    margin: auto;
}