.header {
  text-align: center;
}

.link {
  stroke: #999;
  stroke-opacity: .6;
  stroke-width: 1px;
}

.link_highlighted {
  stroke: #FDDA0D;
  stroke-opacity: .6;
  stroke-width: 2px;
}

.node circle {
  transition: all 0.3s;
  transform-origin: 50% 50%;
}

.node circle:hover {
  r: 10;
  cursor: pointer;
}

.tooltip {
  position: absolute;
  z-index: 10;
  visibility: hidden;
  background-color: #ffffff;
  text-align: center;
  padding: 4px;
  border-radius: 2px;
  border: solid #000;
  border-width: 2px;
  color: #000;
  padding: 10px;
  white-space: pre-wrap;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  width: auto;
}

.button {
  margin: 5px;
  padding: 5px;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-none;
  font-size: 16px;
  border-width: 1px;
  cursor: pointer;
  background-color: rgb(46, 156, 52);
  border: solid rgb(46, 156, 52);
  border-width: 2px;
  transition: all 0.3s ease 0s;
}


.button:hover {
  color: rgb(46, 156, 52);
  background-color: rgb(224, 247, 225);
}

.reset_button {
  margin: 5px;
  padding: 5px;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  border-width: 1px;
  cursor: pointer;
  background-color: red;
  border: solid red;
  border-width: 2px;
  transition: all 0.3s ease 0s;
}

.reset_button:hover {
  color: red;
  background-color: rgb(250, 221, 221);
}

.dropbtn {
  background-color: grey;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
}

.dropdown {
  position: relative;
  display: inline-none;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: none;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: none;
}

.dropdown:hover .dropbtn {
  background-color: #3e8e41;
}

.title-text {
  color: #1976d2;
}

/* Supress scrollspy class for more customized scroll handler (see TableOfContent.tsx) */
/*
.scrollspy > .isCurrent {
  background-color: #e0e0e0;
} */
