.titlesection {
    display: flex;
    width: 100%;
    padding: 2px 164px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 7px;
    background: #254961;
}

.titlesection p {
    color: #EDF0F4;
    text-align: center;
    /*font-family: Inter;*/
    font-size: 18px;
    font-style: normal;
    font-weight: 550;
    line-height: 20px; /* 111.111% */
    text-transform: uppercase; 
}

.roundedBorderContainer {
    margin-top: 10px;
    margin-bottom: 10px;
    padding:5px;
    border: 2px solid #9ab7ca;
    border-radius: 7px;
    align-items: center;
    justify-content: center;
}

.selectedPathContainer {
    width: 70%;
    height: 630px;
}

.interPathsContainer {
    float: left;
    height: 630px;
    margin-right: 10px;
    padding: 10px;
    width: 30%;
    position: relative;
}

.infoContainer {
    float: left;
    height: 630px;
    margin-left: 10px;
    padding: 10px;
    width: 30%;
    position: relative;
}

.interPathsContainer p{
    margin-top: 0px;
    margin-bottom: 0px;
}

.pathsListContainer {
    overflow-y: scroll;
    height: 93%;
}

.smallPathContainer {
    border-radius: 7px;
    /*overflow: hidden;*/
    /*border: 1px solid #d6dce0;*/
    margin-bottom: 5px;
}

.smallPathContainer svg:hover {
    background-color: #f5f5f5;
}

.smallPathGraphContainer {
    border: 1px solid #e8eef0;
    padding: 1px;
    height: 213px;
}

.pathsListContainer Button {
    flex-grow: 1;
    margin-left: 50%;
}

